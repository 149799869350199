












import Vue from "vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import { RecruiterDashboard } from "@/interfaces/recruiter/dashboard/recruiter_dashboard";

export default Vue.extend({
  name: "RecruiterDashboard",
  components: {
    HelloUser
  },
  data(): RecruiterDashboard {
    return {
      stat_error: false,
      stat_loading: true,
      chart_data_members: [],
      chart_data_jobs: [],
      emiratisation_stats: {
        local_count: 0,
        foreign_count: 0,
        total_users: 0
      }
    };
  },
  methods: {}
});
